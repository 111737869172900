import moment from 'moment-timezone';
import ClubActions from '../../../views/Club/Club/ClubAction';
import UserActions from '../../../views/Users/User/UserAction';
import EventActions from '../../../views/Event/Event/EventAcion';
import TeamActions from '../../../views/Team/Team/TeamAction';

// Common Components
import Button from '../../../components/Button';
import Knob from '../../../components/Knob';
import EnrolledUserAction from '../../../views/Event/Event/EnrolledUserAction';

// Utils
import { calculateCO2Saved } from '../../../utils/utils';

export const CLUB = {
    LIST_CLUB: {
        name: (club) => {
            return <div className="flex flex-row justify-start gap-3">
                <div>
                    <img src={`${club?.logo}`} className="h-10 w-10 rounded-full" />
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-semibold font-sm hover:underline hover:cursor-pointer">{ club?.name }</span>
                    <span className="text-xs">{ club?.descriptions?.slice(0, 50) }...</span>
                </div>
            </div>
        },
        email: (club) => {
            return <div>
                <p className="font-semibold text-sm">{club?.email}</p>
            </div>
        },
        createdBy: (club) => {
            return <div className="flex flex-row justify-start gap-2">
                <div>
                    <img src={`${club?.createdBy?.profilePicture}`} className="h-8 w-8 rounded-full" />
                </div>
                <div className="flex gap-1 items-center">
                    <span className="font-semibold text-sm">{ club?.createdBy?.firstName } {club?.createdBy?.lastName }</span>
                    
                </div>
            </div>
        },
        createdAt: (club) => {
            return <span className="font-semibold text-sm">{moment(club.createdAt).tz('Asia/Kolkata').format('MMMM Do YYYY, h:mm:ss a')}</span>
        },
        action: (club) => {
            return <ClubActions club={club} />
        }
    },
    LEADERBOARD: {
        name: (user) => {
            return <div className="flex flex-row justify-start gap-3 items-center">
                <div>
                    <img src={`${user?.profilePicture}`} className="h-10 w-10 rounded-full" />
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-semibold font-sm hover:underline hover:cursor-pointer">{ user?.name }</span>
                </div>
            </div>
        },
        distance: (user) => {
            return <span>{ (user?.total / 1000)?.toFixed(2) } Km</span>
        },
        percentage: (user) => { 
            // return <span>{user?.percentage > 100 ? '100 %' : user?.percentage }</span>
            return <Knob value={user?.percentage > 100 ? 100 : user?.percentage } strokeWidth={10} />
        },
        completeEvents: (user, onClick) => {
            // return <span>{user?.isCompleted && user?.completedAt !== null ? user?.status : (user?.isCompleted ? <Button label='Complete' size='small' onClick={() => {onClick(user)}}/> : <p>{ user?.status }</p> )}</span>
            // return <span>{user?.isCompleted ? <Button label='Complete' size='small' onClick={() => {onClick(user)}}/> : <p>{ user?.status }</p> }</span>
            // return <p>{ user?.status }</p>
        },
        
    }
}

export const USER = {
    LIST_USER: {
        name: (user) => {
            return <div className="flex flex-row justify-start gap-3 items-center">
                <div>
                    <img src={`${user?.profilePicture}`} className="h-10 w-10 rounded-full" />
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-semibold font-sm hover:underline hover:cursor-pointer">{ user?.name }</span>
                </div>
            </div>
        },
        email: (user) => {
            return <div>
                <p className="font-semibold text-sm">{user?.email}</p>
            </div>
        },
        createdBy: (user) => {
            return <div className="flex flex-row justify-start gap-2">
                <div>
                    { user?.createdBy?.profilePicture ? <img src={`${user?.createdBy?.profilePicture}`} className="h-8 w-8 rounded-full" /> : <><span className='font-semibold'>Self Created</span></>}
                </div>
                <div className="flex gap-1 items-center">
                    <span className="font-semibold text-sm">{ user?.createdBy?.firstName } {user?.createdBy?.lastName || '' }</span>
                    
                </div>
            </div>
        },
        createdAt: (user) => {
            return <span className="font-semibold text-sm">{moment(user.createdAt).tz('Asia/Kolkata').format('MMMM Do YYYY, h:mm:ss a')}</span>
        },
        action: (user) => {
            return <UserActions user={user} />
        }
    }
}

export const EVENT = {
    LIST_EVENTS: {
        name: (event) => {
            return <div className="flex flex-row justify-start gap-3 items-center">
                <div>
                    <img src={`${event?.logo}`} className="h-10 w-10 rounded-full" />
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-semibold font-sm hover:underline hover:cursor-pointer">{ event?.name }</span>
                </div>
            </div>
        },
        status: (event) => {
            return <span>{ event?.status }</span>
        },
        distance: (event) => {
            return <>
                <span>{ event?.distance } {event?.unit}</span>
            </>
        },
        fees: (event) => {
            return <>
                <span>Rs. { event?.registrationFee / 100 }</span>
            </>
        },
        startDate: (event) => {
            return <span className="font-semibold text-sm">{moment(event.startDate).tz('Asia/Kolkata').format('MMMM Do YYYY, h:mm:ss a')}</span>
        },
        endDate: (event) => {
            return <span className="font-semibold text-sm">{moment(event.endDate).tz('Asia/Kolkata').format('MMMM Do YYYY, h:mm:ss a')}</span>
        },
        createdBy: (event) => {
            return <div className="flex flex-row justify-start gap-2">
                <div>
                    { event?.createdBy?.profilePicture ? <img src={`${event?.createdBy?.profilePicture}`} className="h-8 w-8 rounded-full" /> : <></>}

                </div>
                <div className="flex gap-1 items-center">
                    <span className="font-semibold text-sm">{ event?.createdBy?.firstName } {event?.createdBy?.lastName || '' }</span>
                    
                </div>
            </div>
        },
        createdAt: (event) => {
            return <span className="font-semibold text-sm">{moment(event.createdAt).tz('Asia/Kolkata').format('MMMM Do YYYY, h:mm:ss a')}</span>
        },
        action: (event) => {
            return <EventActions event={event} />
        }
    },
    LEADERBOARD: {
        user: (user) => {
            return <div className="flex flex-row justify-start gap-2">
                <div>
                    { user?.profilePicture ? <img src={`${user?.profilePicture}`} className="h-8 w-8 rounded-full" /> : <><span className='font-semibold'>Self Created</span></>}
                </div>
                <div className="flex gap-1 items-center">
                    <span className="font-semibold text-sm">{user?.name || '' }</span>
                </div>
            </div>
        },
        distance: (user) => {
            return <>
                <span>{ (user?.distance / 1000)?.toFixed(2) } Km </span>
            </>
        },
        activity: (user) => {
            return <>
                <span>
                    { user?.activity }
                </span>
            </>
        },
        percentage: (user) => { 
            return <Knob value={user?.completionPercentage > 100 ? 100 : user?.completionPercentage } strokeWidth={10} />
        },
        completeEvents: (user, onClick) => {

            return <span>{user?.isCompleted && user?.completedAt !== null ? user?.status : (user?.isCompleted ? <Button label='Complete' size='small' onClick={() => {onClick(user)}}/> : <p>{ user?.status }</p> )}</span>
        },
    },
    REGISTERED_USER: {
        name: (user) => {
            return <div className="flex flex-row justify-start gap-3 items-center">
                <div>
                    <img src={`${user?.profilePicture}`} className="h-10 w-10 rounded-full" />
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-semibold font-sm hover:underline hover:cursor-pointer">{ user?.name }</span>
                </div>
            </div>
        },
        email: (user) => {
            return <div>
                <p className="font-semibold text-sm">{user?.email}</p>
            </div>
        },
        bibNumber: (user) => {
            return <div>
                <p className="font-semibold text-sm">{user?.bibNumber }</p>
            </div>
        },
        phone: (user) => {
            return <div>
                <p className="font-semibold text-sm">{user?.phone }</p>
            </div>
        },
        orderId: (user) => {
            return <div>
                <p className="font-semibold text-sm">{user?.orderId }</p>
            </div>
        },
        paymentId: (user) => {
            return <div>
                <p className="font-semibold text-sm">{user?.paymentId }</p>
            </div>
        },
        paymentStatus: (user) => {
            return <div>
                <p className="font-semibold text-sm">{user?.paymentStatus }</p>
            </div>
        },
        registeredAt: (user) => {
            return <span className="font-semibold text-sm">{moment(user.registeredAt).tz('Asia/Kolkata').format('MMMM Do YYYY, h:mm:ss a')}</span>
        },
        action: (user) => {
            return <EnrolledUserAction user={user} />
        }
    }
}

export const TEAM = {
    LIST_TEAM: {
        name: (team) => {
            return <div className="flex flex-row justify-start gap-3">
                <div>
                    <img src={`${team?.logo}`} className="h-10 w-10 rounded-full" />
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-semibold font-sm hover:underline hover:cursor-pointer">{ team?.name }</span>
                    <span className="text-xs">{ team?.descriptions?.slice(0, 50) }...</span>
                </div>
            </div>
        },
        createdBy: (team) => {
            return <div className="flex flex-row justify-start gap-2">
                <div>
                    <img src={`${team?.createdBy?.profilePicture}`} className="h-8 w-8 rounded-full" />
                </div>
                <div className="flex gap-1 items-center">
                    <span className="font-semibold text-sm">{ team?.createdBy?.firstName } {team?.createdBy?.lastName }</span>
                    
                </div>
            </div>
        },
        createdAt: (team) => {
            return <span className="font-semibold text-sm">{moment(team.createdAt).tz('Asia/Kolkata').format('MMMM Do YYYY, h:mm:ss a')}</span>
        },
        action: (team) => {
            return <TeamActions team={team} />
        }
    },
    LEADERBOARD: {
        name: ({ team }) => {
            return <div className="flex flex-row justify-start gap-3 items-center">
                <div>
                    <img src={`${team?.logo}`} className="h-10 w-10 rounded-full" />
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-semibold font-sm hover:underline hover:cursor-pointer">{ team?.name }</span>
                </div>
            </div>
        },
        distance: (team) => {
            return <span>{ (team?.total / 1000)?.toFixed(2) } Km</span>
        },
        noOfEnrollment: (team) => {
            return <span>{team?.noOfEnrollment}</span>
        },
        co2Saved: (team) => {
            return <span>{ calculateCO2Saved(team?.total / 1000) || 0} kg</span>
        },
        percentage: (team) => { 
            // return <span>{user?.percentage > 100 ? '100 %' : user?.percentage }</span>
            return <Knob value={team?.percentage > 100 ? 100 : team?.percentage } strokeWidth={10} />
        },
        completeEvents: (team) => {

            return <span>{team?.isCompleted ? <Button label='Complete' size='small' onClick={() => {}}/> : <p>{ team?.status }</p> }</span>
        },
        // completed: (team) => {

        // }
    }
}

