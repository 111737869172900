import { LuLayoutDashboard } from "react-icons/lu";
import { IoAppsOutline } from "react-icons/io5";
import { CgGym } from "react-icons/cg";
import { RiRunLine } from "react-icons/ri";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { SlOrganization } from "react-icons/sl";
import { CiEdit } from "react-icons/ci";
import { CiTrash } from "react-icons/ci";
import { HiDotsVertical } from "react-icons/hi";
import { GiCancel } from "react-icons/gi";
import { IoWarningOutline } from "react-icons/io5";
import { MdOutlineEmojiEvents } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { FaCameraRetro } from "react-icons/fa";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { FiEye } from "react-icons/fi";
import { FaPersonRunning } from "react-icons/fa6";
import { AiTwotoneAppstore } from "react-icons/ai";
import { MdLeaderboard } from "react-icons/md";
import { LuActivitySquare } from "react-icons/lu";
import { GiConverseShoe } from "react-icons/gi";
import { CiCalendarDate } from "react-icons/ci";
import { FaClock } from "react-icons/fa";
import { IoMdSpeedometer } from "react-icons/io";
import { FaFire } from "react-icons/fa6";
import { CiUser } from "react-icons/ci";
import { CiLogout } from "react-icons/ci";
import { TbLogout2 } from "react-icons/tb";
import { FaUserTimes } from "react-icons/fa";
import { IoIosBicycle } from "react-icons/io";
import { FaPersonWalking } from "react-icons/fa6";
import { MdEmojiEvents } from "react-icons/md";
import { BsFillCalendar2EventFill } from "react-icons/bs";
import { RiPinDistanceFill } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import { FaHouseUser } from "react-icons/fa";
import { BsClipboardDataFill } from "react-icons/bs";
import { IoMdMailUnread } from "react-icons/io";
import { TbBrandRedux } from "react-icons/tb";
import { FaBolt } from "react-icons/fa6";
import { FaLightbulb } from "react-icons/fa6";
import { FaRocket } from "react-icons/fa";
import { GiPodiumWinner } from "react-icons/gi";
import { FaStar } from "react-icons/fa";
import { FaHandshakeSimple } from "react-icons/fa6";
import { TbRibbonHealth } from "react-icons/tb";
import { FaLeaf } from "react-icons/fa";
import { FaHandHoldingHeart } from "react-icons/fa";
import { BsFillFuelPumpFill } from "react-icons/bs";
import { FaBuildingNgo } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { HiOutlineUserRemove } from "react-icons/hi";
import { GiPineTree } from "react-icons/gi";
import { MdOutlineCo2 } from "react-icons/md";
import { FaGasPump } from "react-icons/fa";

const CLASS_NAMES = {
  ICONS_CLASS: 'w-4 h-4'
}

export const ICONS = {
  DASHBOARD: (className = CLASS_NAMES?.ICONS_CLASS) => <LuLayoutDashboard className={className} />,
  APPS: (className = CLASS_NAMES?.ICONS_CLASS) => <IoAppsOutline className={className} />,
  GYM: (className = CLASS_NAMES?.ICONS_CLASS) => <CgGym className={className} />,
  RUN: (className = CLASS_NAMES?.ICONS_CLASS) => <RiRunLine className={className} />,
  ADMIN: (className = CLASS_NAMES?.ICONS_CLASS) => <MdOutlineAdminPanelSettings className={className} />,
  USERS: (className = CLASS_NAMES?.ICONS_CLASS) => <FaUsers className={className} />,
  ORGANIZATION: (className = CLASS_NAMES?.ICONS_CLASS) => <SlOrganization className={className} />,
  EDIT: (className = CLASS_NAMES?.ICONS_CLASS) => <CiEdit className={className} />,
  DELETE: (className = CLASS_NAMES?.ICONS_CLASS) => <CiTrash className={className} />,
  THREE_DOT: (className = CLASS_NAMES?.ICONS_CLASS) => <HiDotsVertical className={className} />,
  CANCEL: (className = CLASS_NAMES?.ICONS_CLASS) => <GiCancel className={className} />,
  WARNING: (className = CLASS_NAMES?.ICONS_CLASS) => <IoWarningOutline className={className} />,
  EVENT_OUTLINE: (className = CLASS_NAMES?.ICONS_CLASS) => <MdOutlineEmojiEvents className={className} />,
  ADD: (className = CLASS_NAMES?.ICONS_CLASS) => <IoMdAdd className={className} />,
  BACK: (className = CLASS_NAMES?.ICONS_CLASS) => <IoArrowBackCircleOutline className={className} />,
  EYE: (className = CLASS_NAMES?.ICONS_CLASS) => <FiEye className={className} />,
  RUNNING: (className = CLASS_NAMES?.ICONS_CLASS) => <FaPersonRunning className={className} />,
  APPS: (className = CLASS_NAMES?.ICONS_CLASS) => <AiTwotoneAppstore className={className} />,
  LEADER_BOARD: (className = CLASS_NAMES?.ICONS_CLASS) => <MdLeaderboard className={className} />,
  ACTIVITY: (className = CLASS_NAMES?.ICONS_CLASS) => <LuActivitySquare className={className} />,
  SHOE: (className = CLASS_NAMES?.ICONS_CLASS) => <GiConverseShoe className={className} />,
  CALENDAR: (className = CLASS_NAMES?.ICONS_CLASS) => <CiCalendarDate className={className} />,
  CLOCK: (className = CLASS_NAMES?.ICONS_CLASS) => <FaClock className={className} />,
  SPEED: (className = CLASS_NAMES?.ICONS_CLASS) => <IoMdSpeedometer className={className} />,
  FIRE: (className = CLASS_NAMES?.ICONS_CLASS) => <FaFire className={className} />,
  USER: (className = CLASS_NAMES?.ICONS_CLASS) => <CiUser className={className} />,
  LOGOUT: (className = CLASS_NAMES?.ICONS_CLASS) => <CiLogout className={className} />, 
  LOGOUT_V2: (className = CLASS_NAMES?.ICONS_CLASS) => <TbLogout2 className={className} />, 
  IN_ACTIVE_USER: (className = CLASS_NAMES?.ICONS_CLASS) => <FaUserTimes className={className} />, 
  CYCLE: (className = CLASS_NAMES?.ICONS_CLASS) => <IoIosBicycle className={className} />, 
  WALK: (className = CLASS_NAMES?.ICONS_CLASS) => <FaPersonWalking className={className} />, 
  EVENT_CUP: (className = CLASS_NAMES?.ICONS_CLASS) => <MdEmojiEvents className={className} />,
  CALENDAR_BLACK: (className = CLASS_NAMES?.ICONS_CLASS) => <BsFillCalendar2EventFill className={className} />,
  DISTANCE: (className = CLASS_NAMES?.ICONS_CLASS) => <RiPinDistanceFill className={className} />,
  REPORT: (className = CLASS_NAMES?.ICONS_CLASS) => <TbReportAnalytics className={className} />,
  DETAILS: (className = CLASS_NAMES?.ICONS_CLASS) => <FaHouseUser className={className} />,
  METADATA: (className = CLASS_NAMES?.ICONS_CLASS) => <BsClipboardDataFill className={className} />,
  MAIL: (className = CLASS_NAMES?.ICONS_CLASS) => <IoMdMailUnread className={className} />,
  TEAM: (className = CLASS_NAMES?.ICONS_CLASS) => <TbBrandRedux className={className} />,
  BOLT: (className = CLASS_NAMES?.ICONS_CLASS) => <FaBolt className={className} />,
  VISION: (className = CLASS_NAMES?.ICONS_CLASS) => <FaLightbulb className={className} />,
  MISSION: (className = CLASS_NAMES?.ICONS_CLASS) => <FaRocket className={className} />,
  PODIUM: (className = CLASS_NAMES?.ICONS_CLASS) => <GiPodiumWinner className={className} />,
  STAR: (className = CLASS_NAMES?.ICONS_CLASS) => <FaStar className={className} />,
  HAND_SHAKE: (className = CLASS_NAMES?.ICONS_CLASS) => <FaHandshakeSimple className={className} />,
  HEALTH: (className = CLASS_NAMES?.ICONS_CLASS) => <TbRibbonHealth className={className} />,
  LEAF: (className = CLASS_NAMES?.ICONS_CLASS) => <FaLeaf className={className} />,
  HAND: (className = CLASS_NAMES?.ICONS_CLASS) => <FaHandHoldingHeart className={className} />,
  FUEL: (className = CLASS_NAMES?.ICONS_CLASS) => <BsFillFuelPumpFill className={className} />,
  NGO: (className = CLASS_NAMES?.ICONS_CLASS) => <FaBuildingNgo className={className} />,
  INSTAGRAM: (className = CLASS_NAMES?.ICONS_CLASS) => <FaInstagram className={className} />,
  REMOVE_USER: (className = CLASS_NAMES?.ICONS_CLASS) => <HiOutlineUserRemove className={className} />,
  TREE: (className = CLASS_NAMES?.ICONS_CLASS) => <GiPineTree className={className} />,
  CO2: (className = CLASS_NAMES?.ICONS_CLASS) => <MdOutlineCo2 className={className} />,
  PETROL: (className = CLASS_NAMES?.ICONS_CLASS) => <FaGasPump className={className} />,
}


// const ACTIVITY_TYPE = {
//   RUN: ICONS?.RUN,
//   CYCLE: ICONS?.CYCLE,
//   WALK: ICONS?.WALK
// }

export const APP = {
  TITLE: 'India Runathon',
  DESCRIPTION: 'Run Anywhere Anytime!',
  CREDENTIAL: {
    RAYZONPAY: {
      KEY_ID: 'rzp_live_tNpOfh9YebQSfQ'
    }
  }
}

export const SOCIAL_SHARE_BUTTON_TYPE = {
    WHATSAPP: 'whatsapp',
    FACEBOOK: 'facebook',
    TWITTER: 'Twitter',
    LINKEDIN: 'LinkedIn',
}

// activity?.sport_type

export const ACTIVITY_TYPE = {
  RIDE: "Ride",
  RUN: "Run",
  TRAIL_RUN: "TrailRun",
  WALK: "WALK",
  HIKE: "HIKE" ,
  MOUNTAIN_BIKE_RIDE: "MountainBikeRide",
  GRAVEL_RIDE: "GravelRide",
  E_BIKE_RIDE: "EBikeRide",
  E_MOUTAIN_BIKE_RIDE: "EMountainBikeRide",
  VELO_MOBILE: "Velomobile"
}

export const SIDE_BAR = [
    {
      label: 'Dashboard',
      name: 'dashboard',
      route: '/dashboard',
      icon: ICONS?.DASHBOARD('w-6 h-6 m-1')
    },
    {
      label: 'Events',
      name: 'events',
      route: '/event',
      icon: ICONS?.EVENT_OUTLINE('w-6 h-6 m-1')
    },
    {
      label: 'Apps',
      name: 'apps',
      route: '/apps',
      icon: ICONS?.APPS('w-6 h-6 m-1')
    },
    {
      label: 'Clubs',
      name: 'clubs',
      route: '/club',
      icon: ICONS?.ORGANIZATION('w-6 h-6 m-1')
    },
    {
      label: 'Users',
      name: 'users',
      route: '/users',
      icon: ICONS?.USERS('w-6 h-6 m-1')
    },
    {
      label: 'Teams',
      name: 'teams',
      route: '/team',
      icon: ICONS?.TEAM('w-6 h-6 m-1')
    },
    {
        label: 'Admin',
        name: 'admin',
        route: '/admin',
        icon: ICONS?.ADMIN('w-6 h-6 m-1')
    },
    {
      label: 'Profile',
      name: 'profile',
      route: '/settings',
      icon: ICONS?.USER('w-6 h-6 m-1')
    }
]


export const IMAGES = {
  LOGO: 'https://ik.imagekit.io/indiadev2024/Logo/IndiaRunathon_logo?updatedAt=1726899913969',
  AUTH_IMG: 'https://ik.imagekit.io/indiadev2024/Logo/SidePanel?updatedAt=1726899985620'
}

export const PLEDGE_PDF = {
  BLOOD_DONATION: 'https://ik.imagekit.io/indiadev2024/UTILS/Pledge/Blood_Donation.pdf?updatedAt=1726896700106',
  SAY_NO_TO_DOBACK: 'https://ik.imagekit.io/indiadev2024/UTILS/Pledge/Say_No_To_Dobaco.pdf?updatedAt=1726995051942',
}
export const Regex = {
   email : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
   password : /^^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
   // phoneNumber : /^(\+\d{1,3}[- ]?)?\d{10}$/,
   phoneNumber : /^\d{10}$/,
}

export const ICONS_LISTS = () => {
    const keys = Object.keys(ICONS);
    const obj = keys.reduce((acc, cur) => {
        acc[cur] = cur;
        return acc;
    }, {});
    return obj;
}


export const lineChartOptionsTotalSpent = (data) => {
    return {
        legend: {
            show: false,
          }, 
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '50%',
              borderRadius: 5, // Adjust the border radius for top corners
              distributed: true,
              borderRadiusApplication: 'end'
            }
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            borderColor: '#e0e0e0',
            strokeDashArray: 3
          },
          theme: {
            mode: "light",
          },
          chart: {
            type: 'bar',
            height: 350,
            toolbar: {
              show: false
            }
          },
          stroke: {
            curve: "smooth",
          },
          tooltip: {
            style: {
              fontSize: "12px",
              fontFamily: undefined,
              backgroundColor: "#000000"
            },
            theme: 'dark',
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
          xaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: true,
            },
            labels: {
              style: {
                colors: "#A3AED0",
                fontSize: "8px",
                fontWeight: "200",
              },
            },
            type: "text",
            range: undefined,
            categories: data,
            labels: {
              style: {
                colors: '#9e9e9e',
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif'
              }
      }
          },
          yaxis: {
            min: 0,
      max: 25,
      tickAmount: 5,
      labels: {
        style: {
          colors: '#9e9e9e',
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif'
        },
        formatter: (value) => value.toFixed(2) // Display integer values
      }
        },
        fill: {
          opacity: 1,
          colors: ['#1e88e5'] // Color of the bars
        },
    }
    
};


export const HIDE_SIDEBAR_ROUTE = ['connect']

export const APPS = ['strava'];

export const isValidApp = (app) => {
    try {
      return APPS?.includes(app)
    } catch(err) {
      throw err;
    }
}
