import moment from "moment";
import { CLUB, EVENT, TEAM, USER } from "../feature/Reusable/DataTable";

export const TABLE = {
    CLUB: {
        COLUMNS: [
            { field: 'name', header: 'Name', body: CLUB?.LIST_CLUB?.name },
            { field: 'email', header: 'Email', body: CLUB?.LIST_CLUB?.email },
            { field: 'phone', header: 'Phone' },
            { field: 'createdBy', header: 'Created By', body: CLUB?.LIST_CLUB?.createdBy },
            { field: 'createdAt', header: 'Created Date', body: CLUB?.LIST_CLUB?.createdAt },
            { field: 'action', header: 'Action', body: CLUB?.LIST_CLUB?.action }
        ],
        FORMATED: (clubs) => {
            return clubs?.map((club) => {
                return {
                    id: club?._id,
                    name: club?.name,
                    email: club?.email,
                    phone: `${club?.phoneCode} ${club?.phone}`,
                    descriptions: club?.descriptions,
                    logo: club?.logo,
                    createdBy: club?.createdBy,
                    createdAt: club?.createdAt,
                    action: ''
                }
            })
        },
        PUBLIC_CLUB_FORMAT: (clubs) => {
            return clubs?.map((club) => {
                return {
                    name: club?.name,
                    value: club?.id
                }
            })
        },
        GENDER: () => {
            return [
                {
                    name: 'Male',
                    value: 'MALE'
                },
                {
                    name: 'Female',
                    value: 'FEMALE'
                },
                {
                    name: 'Prefer Not To Say',
                    value: 'PREFER_NOT_TO_SAY'
                },
                {
                    name: 'Others',
                    value: 'OTHERS'
                }
            ]
        }
    },
    USER: {
        COLUMNS: [
            { field: 'name', header: 'Name', body: USER?.LIST_USER?.name },
            { field: 'email', header: 'Email', body: USER?.LIST_USER?.email },
            { field: 'phone', header: 'Phone' },
            { field: 'createdBy', header: 'Created By', body: USER?.LIST_USER?.createdBy },
            { field: 'createdAt', header: 'Created Date', body: USER?.LIST_USER?.createdAt },
            { field: 'action', header: 'Action', body: USER?.LIST_USER?.action }
        ],
        FORMATED: (users) => {
            return users?.map((user) => {
                return {
                    id: user?._id,
                    name: `${user?.firstName} ${user?.lastName || ''}`,
                    email: user?.email,
                    profilePicture: user?.profilePicture,
                    verified: user?.isVerified,
                    createdBy: user?.createdBy,
                    createdAt: user?.createdAt,
                    phone: `${user?.phoneCode} ${user?.phone}`,
                    action: '',
                    role: user?.role || '',
                    value: user?._id
                }
            })
        }
    },
    STATE: {
        FORMATED: (states) => {
            return states?.map((state) => {
                return {
                    name: state?.name,
                    value: state?.name,
                    id: state?.stateId
                }
            })
        }
    },
    CITY: {
        FORMATED: (cities) => {
            return cities?.map((city) => {
                return {
                    name: city?.name,
                    value: city?.name
                }
            })
        }
    },
    EVENT: {
        COLUMNS: [
            { field: 'name', header: 'Name', body: EVENT?.LIST_EVENTS?.name },
            { field: 'status', header: 'Status', body: EVENT?.LIST_EVENTS?.status },
            { field: 'distance', header: 'Distance', body: EVENT?.LIST_EVENTS?.distance },
            { field: 'startDate', header: 'Start Date', body: EVENT?.LIST_EVENTS?.startDate },
            { field: 'endDate', header: 'End Date', body: EVENT?.LIST_EVENTS?.endDate },
            { field: 'registrationFee', header: 'Fees', body: EVENT?.LIST_EVENTS.fees },
            { field: 'createdBy', header: 'Created By', body: EVENT?.LIST_EVENTS?.createdBy },
            { field: 'createdAt', header: 'Created At', body: EVENT?.LIST_EVENTS?.createdAt },
            { field: 'action', header: 'Action', body: EVENT?.LIST_EVENTS?.action }

        ],
        FORMATED: (events) => {
            return events?.map((event) => {
                return {
                    id: event?._id,
                    name: event?.name,
                    status: event?.status,
                    distance: event?.distance,
                    unit: event?.unit,
                    logo: event?.logo,
                    startDate: event?.startDate,
                    endDate: event?.endDate,
                    registrationFee: event?.registrationFee,
                    createdBy: event?.createdBy,
                    createdAt: event?.createdAt,
                    action: ''
                }
            })
        },
        USERS: {
            COLUMNS: [
                { field: 'bibNumber', header: 'BIB Number', body: EVENT?.REGISTERED_USER?.bibNumber },
                { field: 'name', header: 'Name', body: EVENT?.REGISTERED_USER?.name },
                { field: 'email', header: 'Email', body: EVENT?.REGISTERED_USER?.email },
                { field: 'phone', header: 'Phone', body: EVENT?.REGISTERED_USER?.phone },
                { field: 'orderId', header: 'Order Id', body: EVENT?.REGISTERED_USER?.orderId },
                { field: 'paymentId', header: 'Payment Id', body: EVENT?.REGISTERED_USER?.paymentId },
                { field: 'paymentStatus', header: 'Payment Status', body: EVENT?.REGISTERED_USER?.paymentStatus },
                { field: 'registeredAt', header: 'Registered At', body: EVENT?.REGISTERED_USER?.registeredAt },
                { field: 'action', header: 'Actions', body: EVENT?.REGISTERED_USER?.action },
            ],
        }
    },
    LEADERBOARD: {
        COLUMNS: (onClick) =>[
            { field: 'name', header: 'Name', body: EVENT?.LEADERBOARD?.user },
            { field: 'distance', header: 'Distance Covered', body: EVENT?.LEADERBOARD?.distance },
            { field: 'activity', header: 'Total Activity', body: EVENT?.LEADERBOARD?.activity },
            { field: 'completionPercentage', header: 'Percentage', body: EVENT?.LEADERBOARD?.percentage },
            { field: 'isCompleted', header: 'Status', body: (rawData) => EVENT?.LEADERBOARD?.completeEvents(rawData, onClick) },
        ],
        FORMATED: (users) => {
            return users?.map((user) => {
                return {
                    id: user?.user?._id,
                    name: `${user?.user?.firstName || ''} ${user?.user?.lastName || ''}`,
                    profilePicture: `${user?.user?.profilePicture || ''}`,
                    distance: user?.totalDistance,
                    activity: user?.totalActivities,
                    completionPercentage: user?.completionPercentage,
                    status: user?.completedAt !== null ? `${user?.status} at ${moment(user?.completedAt)?.format('DD/MM/YYYY hh:mm, A')}` :user?.status,
                    // completedAt: user?.completedAt || null, // Uncommend the code to show the complete button
                    isCompleted: user?.status === 'completed' && (user?.user?._id === JSON.parse(localStorage?.getItem('user'))?._id || JSON.parse(localStorage.getItem('user'))?.role === USER_ROLES[2])
                }
            })
        }
    },
    TEAM: {
        COLUMNS: [
            { field: 'name', header: 'Name', body: TEAM?.LIST_TEAM?.name },
            { field: 'createdBy', header: 'Created By', body: TEAM?.LIST_TEAM?.createdBy },
            { field: 'createdAt', header: 'Created Date', body: TEAM?.LIST_TEAM?.createdAt },
            { field: 'action', header: 'Action', body: TEAM?.LIST_TEAM?.action }
        ],
        FORMATED: (teams) => {
            return teams?.map((team) => {
                return {
                    id: team?._id,
                    name: team?.name,
                    descriptions: team?.descriptions,
                    logo: team?.logo,
                    createdBy: team?.createdBy,
                    createdAt: team?.createdAt,
                    action: ''
                }
            })
        },
        PUBLIC_TEAM_FORMAT: (teams) => {
            return teams?.map((team) => {
                return {
                    name: team?.name,
                    value: team?.id
                }
            })
        }
    },
    TEAM_LEADERBOARD: {
        COLUMNS: (role) => {
            const colum = [
                { field: 'name', header: 'Name', body: TEAM?.LEADERBOARD?.name },
                { field: 'distance', header: 'Distance Covered', body: TEAM?.LEADERBOARD?.distance },
                { field: 'noOfEnrollment', header: 'No Of Enrollments', body: TEAM?.LEADERBOARD?.noOfEnrollment },
                { field: 'co2Saved', header: <span>CO<sub>2</sub> Saved</span>, body: TEAM?.LEADERBOARD?.co2Saved },
                { field: 'percentage', header: 'Percentage', body: TEAM?.LEADERBOARD?.percentage },
                { field: 'completed', header: 'Status', body: TEAM?.LEADERBOARD?.completeEvents },
                // { field: 'activity', header: 'Total Activity', body: EVENT?.LEADERBOARD?.activity },
            ]
            if (role === USER_ROLES[0]) {
                return colum?.filter((col) => col?.field !== 'noOfEnrollment')
            }
            return colum;
        } 
    },
    CLUB_LEADERBOARD: {
        FORMATED: (users) => {
            return users?.map((user) => {
                return {
                    id: user?.user?.id,
                    name: `${user?.user?.firstName || ''} ${user?.user?.lastName || ''}`,
                    profilePicture: user?.user?.profilePicture || '',
                    percentage: user?.percentage || 0,
                    status: user?.status || 'inprogress',
                    target: user?.target || 0,
                    total: user?.total || 0,
                    // isCompleted: user?.status === 'completed'
                    isCompleted: user?.status === 'completed' && (user?.user?._id === JSON.parse(localStorage?.getItem('user'))?._id || JSON.parse(localStorage.getItem('user'))?.role === USER_ROLES[2])
                }
            })
        },
        COLUMNS: [
            { field: 'name', header: 'Name', body: CLUB?.LEADERBOARD?.name },
            { field: 'distance', header: 'Distance Covered', body: CLUB?.LEADERBOARD?.distance },
            { field: 'Percentage', header: 'Completed Percentage', body: CLUB?.LEADERBOARD?.percentage },
            // { field: 'Complete', header: 'Status', body: CLUB?.LEADERBOARD?.completeEvents },
            // { field: 'activity', header: 'Total Activity', body: EVENT?.LEADERBOARD?.activity },
        ]
    }
}


export const toastMessageBuilder = ({ title, message, severity = 'success', life = 3000}) => {
    return { summary: title, detail: message, severity, life }
} 


export const MODULE_ACCESS = {
    USER: {
        DASHBOARD: {
            VIEW_DASHBOARD: true
        },
        EVENTS: {
            VIEW_EVENTS: true,
            ENTROLL_EVENT: true,
            LIST_EVENTS: true,
        },
        APPS: {},
        PROFILE: {
            VIEW_PROFILE: true
        }
    },
    CLUB_ADMIN: {
        DASHBOARD: {
            VIEW_DASHBOARD: true
        },
        EVENTS: {
            VIEW_EVENTS: true,
            LIST_EVENTS: true,
        },
        USERS: {
            VIEW_USER: true
        },
        PROFILE: {
            VIEW_PROFILE: true
        }
    },
    ADMIN: {
        DASHBOARD: {
            VIEW_DASHBOARD: true,
        },
        EVENTS:{ 
            VIEW_EVENTS: true,
            CREATE_EVENTS: true,
            LIST_EVENTS: true,
            DELETE_EVENTS: true
        },
        CLUBS: {
            VIEW_CLUB: true,
            LIST_CLUB: true,
            CREATE_CLUB: true,
            EDIT_CLUB: true
        },
        USERS: {
            VIEW_USER: true,
            DELETE_USER: true,
        },
        TEAMS: {
            VIEW_USER: true,
            DELETE_USER: true,
        },
        ADMIN: {
            VIEW_SUPER_ADMIN: true,
            CREATE_SUPER_ADMIN: true,
            EDIT_SUPER_ADMIN: true,
            DELETE_SUPER_ADMIN: true
        },
        PROFILE: {
            VIEW_PROFILE: true
        }
    }
}

export const USER_ROLES = ['USER', 'CLUB_ADMIN', 'SUPER_ADMIN'];

export const isUser = () => {
    try {
        return JSON.parse(localStorage.getItem('user'))?.role === USER_ROLES[0];
    } catch(err) {
        throw err;
    }
}

export const changeDateFormat = (date) => {
    const formattedDate = moment(date).format('MMMM Do, YYYY');
    return formattedDate;
}


export const tokenExpiration = (expiresAt) => {
    try {
        const expirationDate = moment(expiresAt * 1000);
        // Format the date (customize the format as needed)
        return expirationDate.format('YYYY-MM-DD HH:mm:ss');
    } catch(err) {
        throw err;
    }
}

export const formatMovingTime = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    const secs = Math.floor(duration.seconds());

    let formattedDuration = '';

    if (hours > 0) {
        formattedDuration += `${hours} Hour${hours > 1 ? '' : ''}`;
    }
    if (minutes > 0) {
        if (formattedDuration !== '') {
            formattedDuration += ', ';
        }
        formattedDuration += `${minutes} Min${minutes > 1 ? 's' : ''}`;
    }
    if (secs > 0) {
        if (formattedDuration !== '') {
            formattedDuration += ', ';
        }
        formattedDuration += `${secs} Sec${secs > 1 ? 's' : ''}`;
    }

    return formattedDuration || '0 sec';
};

export const getCurrentWeekDates = () => {
    const currentDate = moment(); 
    const startOfWeek = currentDate.clone().startOf('week');
    const endOfWeek = currentDate.clone().endOf('week');

    // Return the start and end dates
    return {
        startDate: startOfWeek.format('YYYY-MM-DD'),
        endDate: endOfWeek.format('YYYY-MM-DD')
    };
};

export const formatDate = (date, format = 'YYYY-MM-DD') => {
    return moment(date)?.format(format);
}

export const isDayBefore = (startDate, endDate) => {
    try {
        return moment(startDate)?.isBefore(endDate)
    } catch(err) {
        throw err;
    }
}


export const calculateCO2Saved = (distance) => {
    try {
        return (distance * 0.24)?.toFixed(2);
    } catch(err) {
        return 0;
    }
}